import React from 'react';
import cx from 'classnames';
// import styles from './create-steps.m.scss';

export default () => {
  return (
    <div className={cx('')}>
      <span role="img" aria-label="">😕 We can't find what you're looking for.</span>
    </div>
  );
};

