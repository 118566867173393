import React, { Component } from 'react';

class Scrolling extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAtBottom: false,
    };
    this.checkScroll = this.checkScroll.bind(this);
    this.container = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.checkScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkScroll, true);
  }

  checkScroll(event) {
    let scroller = event.srcElement;

    if (scroller.scrollHeight - scroller.scrollTop - scroller.clientHeight < 1) {
      this.container.current.classList.add('is-hidden');
      this.setState({isAtBottom: true});
    } else {
      this.container.current.classList.remove('is-hidden');
      this.setState({isAtBottom: false});
    }
  }

  render() {
    const message = (<p>There are more leagues to see!<br />Click the filters to see more.</p>);
    return (
      <div className="helper" ref={this.container}>{this.props.children}{message}</div>
    );
  }
};

export default Scrolling;
