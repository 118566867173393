import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
// import _ from 'lodash';
import classNames from 'classnames/bind';
import {getSportIconColor} from '../../constants/get-sport-assets';
import styles from './league-cards.module.scss';

import date from '../../images/date.svg';
import cup from '../../images/cup.svg';
import user_single from '../../images/user_single.svg';
import users_icon from '../../images/users.svg';
import users_team from '../../images/users_team.svg';
import alarm from '../../images/alarm.svg';
import marker from '../../images/map-marker.svg';

const cx = classNames.bind(styles);

const CardItem = (props) => {
    const {
        league: {
            name,
            currentIndvPrice,
            currentCorpPrice,
            currentPricePhase,
            daysOfWeek,
            endTimeStr,
            minTeamSize,
            numWeeks,
            photoSrc,
            registrationEnd,
            rivallUrl,
            sponsorBar,
            sportName,
            startDate,
            startTimeStr,
            venue,
            venueDetails,
            bannerText,
        },
    } = props;

  const icon = getSportIconColor(sportName);

  return (
    <a target="_blank" href={rivallUrl} className={cx('discover-league-card')}>
      { photoSrc && <img className={cx('league-image', { 'default-img': !photoSrc })} src={photoSrc} alt="" /> }

      <div className={cx('league-info')}>
        <h3 className={cx('league-name')}>
          <img className="hide-mobile" aria-hidden="true" src={ icon } alt="" />
          { name }
        </h3>
          <p className={cx('league-location')}>
          <img className="hide-mobile" aria-hidden="true" src={marker} alt=""/>
          { venue.name }
        </p>
        <div className={cx('league-extralocation')}>
          {venueDetails && <p><strong>Field Location</strong><span>{ venueDetails }</span></p> }
          <p><strong>Sponsor Bar</strong>{sponsorBar && sponsorBar.name ? <span>{sponsorBar.name}</span> : <span>Coming Soon</span>}</p>
        </div>
      </div>

      <div className={cx('league-grid')}>
        <p className={cx('league-grid_item')}>
          <img aria-hidden="true" src={date} alt=""/>
          { daysOfWeek }
        </p>
        <p className={cx('league-grid_item')}>
          <img aria-hidden="true" src={date} alt=""/>
          { startDate && `Begins ${moment(startDate, 'MM/DD/YY').format('M/DD')}` }
        </p>
        <p className={cx('league-grid_item')}>
          <img aria-hidden="true" src={date} alt=""/>
          {numWeeks} weeks
        </p>
        <p className={cx('league-grid_item')}>
          <img aria-hidden="true" src={alarm} alt=""/>
          {moment(startTimeStr, 'HH:mm').format('h:mma')} - {moment(endTimeStr, 'HH:mm').format('h:mma')}
        </p>
        <p className={cx('league-grid_item','hide-mobile')}>
          <img aria-hidden="true" src={users_icon} alt=""/>
          Group: 2 - {minTeamSize - 1} players
        </p>
        <p className={cx('league-grid_item','hide-mobile')}>
          <img aria-hidden="true" src={users_team} alt=""/>
          Team: {minTeamSize}+ players
        </p>
      </div>

      <div className={cx('league-registration')}>
        {/* <p className={cx('league-pricing')}>
          <span><strong>Individual</strong> &mdash; ${currentIndvPrice}</span>
          <span><strong>Team</strong> &mdash; ${currentCorpPrice}</span>
        </p> */}
        <span className="button mb-2">Register</span>
        <p>Registration ends: {registrationEnd}</p>
      </div>

    {bannerText && <p className="banner">{ bannerText }</p> }
    </a>
  );
};

CardItem.propTypes = {
    league: PropTypes.shape({
        rivallUrl: PropTypes.string.isRequired,
    }).isRequired,
};

export default CardItem;
