const env = process.env.NODE_ENV;

export const isDevelopment = env === 'development';

// export const baseUrl = isDevelopment ? 'http://localhost:3000' : 'https://app.rivall.io';
export const baseUrl = 'https://www.volosports.com';

export const google = {
    clientID: process.env.GOOGLE_CLIENTID || '62351010161-eqcnoa340ki5ekb9gvids4ksgqt9hf48.apps.googleusercontent.com',
    clientSecret: process.env.GOOGLE_SECRET || '6cKCWD75gHgzCvM4VQyR5_TU',
    callbackURL: process.env.GOOGLE_CALLBACK || '/auth/google/callback',
    mapsAPIkey: 'AIzaSyCGXmBfRXzvRuIIqGfVwtjUHvmTQa7axw0',
};

export default env;
