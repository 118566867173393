import React from 'react';
import styles from './styles.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);


const Loader = () => {
  return (
    <div className={cx('simple-loader')} />
	);
};

export default Loader;
