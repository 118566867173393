/* global window */

import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { getSportIconColor, getSportIconWhite } from '../../constants/get-sport-assets';
import styles from './styles.scss';
import MultiSelect from "@khanacademy/react-multi-select";
import DowEnum from '../../constants/days-of-week-enum';
import Button from '../Button';

const cx = classNames.bind(styles);

const filterWidth = 70;
const filterMarginX = 5;
const filterBoxSize = (filterMarginX * 2) + filterWidth;

class LeagueListFilters extends Component {
  constructor(props) {
    super(props);

    this.sportListContainer = null;
    this.sportListButtons = null;

    this.state = {
      allSports: [], // all sports for the leagues we have.
      allDays: [], // all days for the leagues we have.
      allHoods: [], // all Neighborhoods for the leagues we have.
      visibleSports: [], // sports for the leagues with the selected day, if any.
      visibleDays: [], // days of the leagues with the selected sport, if any.
      visiblePhases: [], // sports for the leagues with the selected day, if any.
      hasOverflow: false, // if the scroll arrows should be shown.
      sportFilterX: 0, // the positioning of the scroll.
    };
  }

  componentDidMount() {
    // this.setupFilters();
    window.addEventListener('resize', this.setHasOverflow);
  }

  // componentDidUpdate(prevProps) {
  //   const { activeDay: prevActiveDay, leagues: prevLeagues } = prevProps;
  //   const { activeDay, leagues } = this.props;
  //   if (activeDay !== prevActiveDay
  //     || (leagues || []).length !== (prevLeagues || []).length) {
  //     // this.setupFilters();
  //   }
  // }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHasOverflow);
  }

  setHasOverflow = () => {
    // Determine if the window width is wider than the filter list, center content if not.
    const { allSports } = this.state;
    const filterContainerWidth = Math.round(allSports.length * filterBoxSize) - (filterBoxSize / 2);
    const windowWidth = document.documentElement.clientWidth;
    const hasOverflow = filterContainerWidth >= windowWidth;
    this.setState({
      hasOverflow,
      ...!hasOverflow ? { sportFilterX: 0 } : {},
    });
  }

  setupFilters = () => {
    const {
      leagues,
      activeDay,
      activeGender,
      activeSports,
      activeHoods,
      activePhase,
    } = this.props;

    // get lists of all the sports/days we have.
    const allFilters = _.reduce(leagues, (acc, { sportName, daysOfWeek, neighborhood, currentPricePhase }) => ({
      sports: {
        ...acc.sports,
        [sportName]: {
          name: sportName,
          icon: getSportIconColor(sportName),
          altIcon: getSportIconWhite(sportName),
        },
      },
      days: [
        ...acc.days,
        ...daysOfWeek,
      ],
      neighborhoods: [
        ...acc.neighborhoods,
        neighborhood,
      ],
      phases: [
        ...acc.phases,
        currentPricePhase,
      ]
    }), {
      sports: {},
      days: [],
      neighborhoods: [],
      phases: [],
    });

    let visibleDays = [];
    let visibleHoods = [];
    let visiblePhases = [];

    // if a sport is selected, get all the possible day options
    if (activeSports) {
      const leagueMatches = leagues.filter(({ sportName }) => activeSports.indexOf(sportName) !== -1);
      visibleDays = leagueMatches.map(({ daysOfWeek }) => daysOfWeek).toString();
      visibleHoods = leagueMatches.map(({ neighborhood }) => neighborhood).toString();
    }

    // if a day is selected, get all the possible sport options
    if (activeDay) {
      const leagueMatches = leagues.filter(({ daysOfWeek }) => daysOfWeek.includes(activeDay));
      visiblePhases = _.uniq(_.flatten(leagueMatches.map(({ currentPricePhase }) => currentPricePhase)));
      visibleHoods = leagueMatches.map(({ neighborhood }) => neighborhood).toString();
    }

    if (activeHoods) {
      const leagueMatches = leagues.filter(({ neighborhood }) => activeHoods.indexOf(neighborhood) !== -1);
      visibleDays = leagueMatches.map(({ daysOfWeek }) => daysOfWeek).toString();
      visiblePhases = _.uniq(_.flatten(leagueMatches.map(({ currentPricePhase }) => currentPricePhase)));
    }

    if (activePhase) {
      const leagueMatches = leagues.filter(({ currentPricePhase }) => currentPricePhase === activePhase);
      visibleDays = leagueMatches.map(({ daysOfWeek }) => daysOfWeek).toString();
      visibleHoods = leagueMatches.map(({ neighborhood }) => neighborhood).toString();
    }

    if (activeGender) {
      const leagueMatches = leagues.filter(({ gender }) => gender === activeGender);
      visibleDays = leagueMatches.map(({ daysOfWeek }) => daysOfWeek).toString();
      visibleHoods = leagueMatches.map(({ neighborhood }) => neighborhood).toString();
    }

    this.setState({
      allSports: _.toArray(allFilters.sports),
      allDays: _.uniq(allFilters.days),
      allHoods: _.uniq(allFilters.neighborhoods),
      allPhases: _.uniq(allFilters.phases),
      visibleDays,
      visibleHoods,
      visiblePhases,
    }, this.setHasOverflow);
  }

  updateInfoArray(setFunc, item) {
    const { updateView } = this.props;
    setFunc(item, () => updateView());
  }

  updateInfoButton(setFunc, arr, item) {
    const { updateView } = this.props;
    if (_.includes(arr, item)) {
      setFunc(_.filter(arr, i => i !== item), () => updateView());
    } else {
      setFunc(_.union(arr, [item]), () => updateView());
    }
  }

  render() {
    const {
      leagues,
      selectedSports,
      availableSports,
      availableHoods,
      selectedHoods,
      setSelectedSports,
      setSelectedHood,
      setSelectedGenders,
      setSelectedDays,
      selectedDays,
      selectedGenders,
      clearFilters,
    } = this.props;

    const {
      visibleDays, // days of all the leagues with the selected sport, if any.
    } = this.state;

    if (_.isEmpty(leagues)) {
      return null;
    }

    const sportOptions = _.map(availableSports, (sport) => ({
      label: sport,
      value: sport,
    }));

    const hoodOptions = _.map(availableHoods, (hood) => ({
      label: hood,
      value: hood,
    }));

    const genderOptions = [{ name: "Coed", shortName: "coed" }, { name: "Men's", shortName: "men" }, { name: "Women's", shortName: "women" }];

    return (
      <div className={cx('discover-filters')}>
        <div className={cx('sports')}>
          <MultiSelect
            options={sportOptions}
            selected={selectedSports || []}
            onSelectedChanged={selected => this.updateInfoArray(setSelectedSports, selected)}
            disableSearch
            overrideStrings={{
              selectSomeItems: "Select Sports...",
            }}
          />
        </div>

        <div className={cx('areas')}>
          <MultiSelect
            options={hoodOptions}
            selected={selectedHoods || []}
            onSelectedChanged={selected => this.updateInfoArray(setSelectedHood, selected)}
            disableSearch
            overrideStrings={{
              selectSomeItems: "Select Neighborhoods...",
            }}
          />
        </div>

        <div className={cx('day-filters-list')}>
          {_.map(DowEnum, (day) => {
            const isSelected = _.includes(selectedDays, day.shortName);
            const hasVisibleLeagues = !_.get(visibleDays, '0')
              || visibleDays.includes(day.shortName);
            return (
              <Button
                small
                {...isSelected ? { action: true } : { default: true }}
                key={`day${day.abbrevName}`}
                onClick={() => this.updateInfoButton(setSelectedDays, selectedDays, day.shortName)}
                style={{ opacity: hasVisibleLeagues ? 1 : 0.5 }}
              >
                {day.shortName}
              </Button>
            );
          })}
        </div>

        <div className={cx('gender-filters-list')}>
          {_.map(genderOptions, (opt) => {
            const isSelected = _.includes(selectedGenders, opt.shortName);
            return (
              <Button
                small
                {...isSelected ? { action: true } : { default: true }}
                key={`day${opt.shortName}`}
                onClick={() => this.updateInfoButton(setSelectedGenders, selectedGenders, opt.shortName)}
              >
                {opt.name}
              </Button>
            );
          })}
        </div>

        <Button
          small
          onClick={() => clearFilters()}
          className="ml-5"
        >
          Clear All Filters
        </Button>
        {/* <PhaseFilters {...{allPhases, visiblePhases, activePhase}} setActivePhase={setActivePhase} /> */}
      </div>
    );
  }
}

LeagueListFilters.propTypes = {
  leagues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  visibleLeagues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setActiveSports: PropTypes.func.isRequired,
  setActiveDay: PropTypes.func.isRequired,
  setActiveHoods: PropTypes.func.isRequired,
  setActivePhase: PropTypes.func.isRequired,
  setActiveGender: PropTypes.func.isRequired,
  activeSports: PropTypes.arrayOf(PropTypes.string),
  activeDay: PropTypes.arrayOf(PropTypes.string),
  activeHoods: PropTypes.arrayOf(PropTypes.string),
  activeGender: PropTypes.string,
};

LeagueListFilters.defaultProps = {
  activeSports: [],
  activeDay: [],
  activeHoods: [],
  activePhase: null,
  activeGender: null,
};

export default LeagueListFilters;
