import React from 'react';
import classNames from 'classnames/bind';
import styles from './styles.scss';
import Button from '../../components/Button';
import list from '../../images/list.svg';
import grid from '../../images/grid.svg';
import map from '../../images/map-marker.svg';
import whiteList from '../../images/white/list.svg';
import whiteGrid from '../../images/white/grid.svg';
import whiteMap from '../../images/white/map-marker.svg';

const cx = classNames.bind(styles);

const LeagueView = (props) => {
    const { setGrid, showGrid, showMaps, setMaps } = props;
    const gridIcon = showGrid ? whiteGrid : grid;
    const listIcon = showGrid ? list : whiteList;
    const mapIcon = showMaps ? whiteMap : map;

    const mapsText = showMaps ? "Hide Map" : "View Map";

    return (
      <div className={cx('card-toggle')}>
          <Button
              small
              onClick={() => setMaps(!showMaps)}
              {...showMaps ? { action: true } : { default: true }}
          >
              <img className="hide-mobile" aria-hidden="true" src={ mapIcon } alt="" /> {mapsText}
          </Button>
        <p data-mobile="Detailed view:" data-desktop="View as:"></p>
        <Button
            small
            onClick={() => setGrid(false)}
            {...showGrid ? { default: true } : { action: true }}
        >
          <img className="hide-mobile" aria-hidden="true" src={ listIcon } alt="" /><span data-mobile="On" data-desktop="List"></span>
        </Button>
        <Button
            small
            onClick={() => setGrid(true)}
            {...showGrid ? { action: true } : { default: true }}
        >
          <img className="hide-mobile" aria-hidden="true" src={ gridIcon } alt="" /><span data-mobile="Off" data-desktop="Grid"></span>
        </Button>
      </div>
    );
}


export default LeagueView;