/* global google */
import _ from 'lodash';
import React, { Component } from 'react';
import { compose, withProps, withHandlers } from 'recompose';
import {
    withGoogleMap, GoogleMap, Marker, InfoWindow,
} from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { google as googleKeys } from './constants/env-settings';
import mapStyles from './components/LeaguesMap/map-styles';

const markerSize = 40;

class LeaguesMap extends Component {
    constructor(props) {
        super(props);
        this.getBounds = this.getBounds.bind(this);
        this.getCenterPoint = this.getCenterPoint.bind(this);
        this.handleMapChange = this.handleMapChange.bind(this);
        this.handleMarkerClick = this.handleMarkerClick.bind(this);
        this.handleClusterClick = this.handleClusterClick.bind(this);
        this.setRef = this.setRef.bind(this);
        this.state = {
            infoWindow: null,
            clusterInfoWindow: null,
            mapRef: null,
            // while this is false, we listen for new props and set the map bounds.
            // once the map initializes, this becomes true.
            boundsDidChange: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            mapRef,
            boundsDidChange,
            infoWindow,
            clusterInfoWindow,
        } = this.state;
        const {
            leagues,
        } = this.props;

        // if a new info window was opened, don't update bounds.
        const newWindow = infoWindow !== prevState.infoWindow
            || clusterInfoWindow !== prevState.clusterInfoWindow;

        if (mapRef && leagues && leagues.length && !boundsDidChange && !newWindow) {
            const bounds = this.getBounds();
            mapRef.fitBounds(bounds);
        }
    }

    getBounds() {
        const bounds = new google.maps.LatLngBounds();
        const { leagues } = this.props;
        _.map(leagues, (mapItem) => {
            bounds.extend({
                lat: Number(mapItem.venue.location.lat),
                lng: Number(mapItem.venue.location.lng),
            });
            bounds.extend(mapItem.venue.viewport.northeast);
            bounds.extend(mapItem.venue.viewport.southwest);
        });
        return bounds;
    }

    getCenterPoint(firstItem) {
        const { mapRef, boundsDidChange } = this.state;

        let center = { lat: 39, lng: -100 };
        let zoom = 5;

        if (mapRef && boundsDidChange) {
            const mapCenter = mapRef.getCenter();
            center = {
                lat: mapCenter.lat(),
                lng: mapCenter.lng(),
            };
            zoom = mapRef.getZoom();
            return { center, zoom };
        }

        if (firstItem && firstItem.venue.location.lat) {
            center = {
                lat: Number(firstItem.venue.location.lat),
                lng: Number(firstItem.venue.location.lng),
            };
            zoom = 13;
        }

        return { center, zoom };
    }

    handleMarkerClick(data, index) {
        this.setState({ infoWindow: index, clusterInfoWindow: null });
    }

    handleClusterClick(clusterer) {
        const here = clusterer.getMarkers();
        const location = clusterer.getCenter();
        const idsHere = _.map(here, l => l.title);
        this.setState({
            clusterInfoWindow: {
                ids: idsHere,
                location,
            },
            infoWindow: null,
        });
    }

    handleMapChange() {
        // DO NOTHING

        // const { setBounds } = this.props;
        // this.setState({
        //     boundsDidChange: true,
        // });
        //
        // const { mapRef } = this.state;
        // const currentBounds = mapRef.getBounds();
        //
        // const neBounds = currentBounds.getNorthEast();
        // const swBounds = currentBounds.getSouthWest();
        //
        // setBounds({
        //     ne: {
        //         lat: neBounds.lat(),
        //         lng: neBounds.lng(),
        //     },
        //     sw: {
        //         lat: swBounds.lat(),
        //         lng: swBounds.lng(),
        //     },
        // });
    }

    setRef(ref) {
        this.setState({ mapRef: ref });
    }

    render() {
        const { infoWindow, clusterInfoWindow } = this.state;
        const data = this.props.leagues;

        const { center, zoom } = this.getCenterPoint(data[0]);

        return (
            <div style={{ width: '100%' }}>
                <MapWithAMarker
                    handleMarkerClick={this.handleMarkerClick}
                    handleClusterClick={this.handleClusterClick}
                    clusterInfoWindow={clusterInfoWindow}
                    closeWindow={() => this.setState({ infoWindow: null, clusterInfoWindow: null })}
                    infoWindow={infoWindow}
                    center={center}
                    zoom={zoom}
                    data={data}
                    setRef={this.setRef}
                />
            </div>
        );
    }
}

const mapOps = {
    googleMapURL: `https://maps.googleapis.com/maps/api/staticmap?key=${googleKeys.mapsAPIkey}&format=png&maptype=roadmap`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div className="mapContainer" />,
    mapElement: <div style={{ height: '100%' }} />,
};
const MapWithAMarker = compose(
    withProps(mapOps),
    withHandlers({
        onMarkerClustererClick: () => (markerClusterer) => {
        },
    }),
    withGoogleMap,
)(props => (
    <GoogleMap
        ref={props.setRef}
        defaultOptions={{
            styles: mapStyles,
            streetViewControl: false,
            mapTypeControl: false,
            panControl: false,
            fullscreenControl: false,
        }}
        defaultZoom={props.zoom}
        defaultCenter={props.center}
        zoom={props.zoom}
        onDragEnd={props.handleMapChange}
        clickableIcons={false}
        maxZoom={10}
    >

        {props.clusterInfoWindow && props.clusterInfoWindow.location
            ? (
                <InfoWindow
                    position={props.clusterInfoWindow.location}
                    onCloseClick={props.closeWindow}
                >
                    <ClusterInfo leagues={props.data} ids={props.clusterInfoWindow.ids} />
                </InfoWindow>
            ) : null }

        <MarkerClusterer
            onClick={props.handleClusterClick}
            averageCenter
            enableRetinaIcons
            gridSize={8}
            zoomOnClick={false}
            styles={[{
                url: '',
                height: markerSize,
                width: markerSize,
                lineHeight: markerSize,
            }]}
        >
            {/* HACK league.venue._id.geometry.location */}
            {_.map(props.data, (item, i) => {
                let location;
                if (item && item.venue) {
                    location = {
                        lat: Number(item.venue.location.lat),
                        lng: Number(item.venue.location.lng),
                    };
                }
                const map_icon = item.sportIcon;

                if (!location) return;
                return (
                    <span key={`marker${i}`}>
            <Marker
                icon={{
                    url: map_icon,
                    scaledSize: new window.google.maps.Size(markerSize, markerSize),
                }}
                onClick={val => props.handleMarkerClick(val, i)}
                mapHolderRef={item.id}
                title={item.id}
                position={location}
            >
              { props.infoWindow === i && (
                  <InfoWindow onCloseClick={props.closeWindow}>
                      <ItemInfo item={item} />
                  </InfoWindow>
              )}
            </Marker>
          </span>
                );
            })}
        </MarkerClusterer>
    </GoogleMap>
));

const ClusterInfo = ({ ids, leagues }) => {
    const byId = _.keyBy(leagues, 'id');
    console.log(ids);
    const here = _.pick(byId, ids);
    return (
        <div className="cluster-popup">
            {_.map(here, lg => <ItemInfo key={lg.id} item={lg} />)}
        </div>
    );
};

const ItemInfo = ({ item }) => {
    const icon = item.sportIcon;
    return (
        <div className="item-info">
            <img src={icon} className="league-icon" alt="" />
            <div>
                <a target="_blank" href={`https://app.rivall.io/liinv/${item.id}`} rel="noopener noreferrer">
                    <p className="league-name">
                        {item.name}
                    </p>
                    <p className="small">
                        {item.sportName}
                    </p>
                </a>
            </div>
        </div>
    );
};

export default LeaguesMap;
