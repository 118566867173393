import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './league-cards.module.scss';
import CardItem from './CardItem';

const cx = classNames.bind(styles);

const LeagueCards = (props) => {
  const { activeSports, activeDay, activeHoods, activeGender, clearFilters, leagues, showGrid } = props;
  const noLeagues = !leagues || (Array.isArray(leagues) && !leagues.length) || _.isEmpty(leagues);
  const noSport = !activeSports || !activeSports.length || activeSports == null || _.isEmpty(activeSports);
  const noDay = !activeDay || !activeDay.length || activeDay == null || _.isEmpty(activeDay);
  const noHood = !activeHoods || !activeHoods.length || activeHoods == null || _.isEmpty(activeHoods);
  const noGender = !activeGender || !activeGender.length || activeGender == null || _.isEmpty(activeGender);

  //console.log(showGrid);
  return (
    <div className={cx('league-cards', { 'grid': showGrid })}>
      {noLeagues && (
        <div className={cx('no-leagues', 'text-center')} style={{ minHeight: '300px' }}>
          <p>We don't currently have any leagues with the filters chosen.</p>
          <p>Click below to clear all filters and try something else!</p>
          <button className="button" onClick={() => clearFilters()}>Clear Filters</button>
        </div>
      )}

      {_.map(leagues, league => (
        <CardItem
          key={`lli${league.rivallUrl}`}
          league={league}
        />
      ))}

    </div>
  );
};

LeagueCards.propTypes = {
  leagues: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default LeagueCards;
