// withLeagueFilterHandlers (copied & modified from main app)
// Can be added to any parent that uses LeagueListFilters.
// Will pass all needed filter state handlers and a visibleLeagues
//   array that only includes leagues the filters apply to, if present

import PropTypes from 'prop-types';
import {
  compose, withProps, setDisplayName, withStateHandlers, setPropTypes,
} from 'recompose';

const filterableLeaguePropType = PropTypes.shape({
  sportName: PropTypes.string.isRequired,
  daysOfWeek: PropTypes.string.isRequired,
  neighborhood: PropTypes.string.isRequired,
  currentPricePhase: PropTypes.string.isRequired,
  gender: PropTypes.string,
});

// props the children of this HOC can expect
export const withLeagueFilterHandlerPropTypes = {
  visibleLeagues: PropTypes.arrayOf(filterableLeaguePropType).isRequired,
  setActiveSports: PropTypes.func.isRequired,
  setActiveDay: PropTypes.func.isRequired,
  setActiveHoods: PropTypes.func.isRequired,
  setActivePhase: PropTypes.func.isRequired,
  setActiveGender: PropTypes.func.isRequired,
  activeSports: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeDay: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeHoods: PropTypes.arrayOf(PropTypes.string).isRequired,
  activePhase: PropTypes.string,
  activeGender: PropTypes.string,
};

export default _options => WrappedComponent => compose(
  setDisplayName('withLeagueFilterHandlers'),
  setPropTypes({
    leagues: PropTypes.arrayOf(filterableLeaguePropType).isRequired,
  }),
  withStateHandlers(() => ({ activeSports: [], activeDay: null, activeHoods: [], activePhase: null, activeGender: null }), {
    setActiveSports: ({ activeSports: currActiveSports }) => activeSports => ({
      activeSports: currActiveSports === activeSports ? [] : activeSports,
    }),
    setActiveDay: ({ activeDay: currActiveDay }) => activeDay => ({
      activeDay: currActiveDay === activeDay ? [] : activeDay,
    }),
    setActiveHoods: ({ activeHoods: currActiveHoods }) => activeHoods => {
      return {
        activeHoods: currActiveHoods === activeHoods ? [] : activeHoods,
      };
    },
    setActivePhase: ({ activePhase: currActivePhase }) => activePhase => ({
      activePhase: currActivePhase === activePhase ? null : activePhase,
    }),
    setActiveGender: ({ activeGender: currActiveGender }) => activeGender => {
      //console.log(activeGender);
      return {
        activeGender: currActiveGender === activeGender ? null : activeGender,
      };
    }
  }),
  withProps(({ activeDay, activeSports, activeHoods, activePhase, activeGender, leagues }) => ({
    visibleLeagues: (leagues || []).filter(({ daysOfWeek, sportName, neighborhood, currentPricePhase, gender }) => {
      if (activePhase && activePhase !== currentPricePhase) {
        return false;
      }
      if (activeHoods && activeHoods.length && activeHoods.indexOf(neighborhood) === -1) {
        return false;
      }
      if (activeSports && activeSports.length && activeSports.indexOf(sportName) === -1) {
        return false;
      }
      // if (activeDay && !daysOfWeek.includes(activeDay)) {
      //   return false;
      // }
      if (activeGender && activeGender !== gender) {
        return false;
      }

      return true;
    }),
  })),
)(WrappedComponent);
