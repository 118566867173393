/*
  Controller
  Contains all the main routes. Todo: lazy-loading/chunks
*/

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HostLeagues from './HostLeagues.jsx';
import ErrorPage from './ErrorPage.jsx';
import './styles/main.scss';

export default () => (
  <Router>
    <Switch>
      <Route path="/host/:organizationId" component={HostLeagues} />
      <Route path="*" name="error" component={ErrorPage} />
    </Switch>
  </Router>
);

