import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router';
import axios from 'axios';
import { compose, withState, lifecycle, withHandlers } from 'recompose';
import cx from 'classnames';
import { baseUrl } from './constants/env-settings';
import withLeagueFilterHandlers from './components/LeagueListFilters/withLeagueFilterHandlers';
import LeagueListFilters from './components/LeagueListFilters/LeagueListFilters';
import LeagueView from './components/LeagueView/LeagueView';
import LeagueCards from './components/LeagueCards/LeagueCards';
import LeaguesMap from './LeaguesMap';
import Loading from './components/Loading/Loading';
import Scrolling from './components/Scrolling/Scrolling';

const HostLeaguesPage = (props) => {
  const {
    leagues,
    showMaps,
    setGrid,
    setMaps,
    visibleLeagues,
    activeSports,
    activeDay,
    activeGender,
    activeHoods,
    setActiveSports,
    setActiveDay,
    setActiveHoods,
    setActivePhase,
    setActiveGender,
    showGrid,
    loading,
    error,
    totalCount,
    updateView,
    availableSports,
    availableHoods,
    setSelectedSports,
    setSelectedHood,
    setSelectedGenders,
    setSelectedDays,
    selectedSports,
    selectedHoods,
    selectedDays,
    selectedGenders,
  } = props;

  const clearFilters = async () => {
    await setSelectedSports([]);
    await setSelectedHood([]);
    await setSelectedGenders([]);
    await setSelectedDays([]);
    updateView();
  }

  const showScrolling = visibleLeagues && ((showGrid && visibleLeagues.length > 3) || (!showGrid && visibleLeagues.length > 2));

  return (
    <div className="frameWrap">
      {!loading && showMaps ?
        <LeaguesMap
          leagues={visibleLeagues}
        />
        : null}
      <LeagueListFilters
        {...{
          leagues,
          visibleLeagues,
          activeSports,
          activeDay,
          activeHoods,
          activeGender,
          setActiveSports,
          setActiveDay,
          setActiveHoods,
          setActivePhase,
          setActiveGender,
          updateView,
          availableSports,
          availableHoods,
          selectedSports,
          setSelectedSports,
          setSelectedHood,
          selectedHoods,
          setSelectedGenders,
          setSelectedDays,
          selectedDays,
          selectedGenders,
          clearFilters,
        }}
      />
      <div className="container">
        {loading && <div className={cx('py-4')}><Loading /></div>}
        {error && <div className={cx('py-4')}>{error}</div>}
        {!loading && !error && (
          <React.Fragment>
            <h2 className="mb-4">Use the filters above to narrow down your search</h2>
            <LeagueView
              setGrid={setGrid}
              showGrid={showGrid}
              setMaps={setMaps}
              showMaps={showMaps}
            />
            <h3 className="num-leagues mb-3">Showing {visibleLeagues.length} {(visibleLeagues.length === 1) ? 'League' : 'Leagues'} out of {totalCount} Total Leagues</h3>
            <LeagueCards
              activeSports={activeSports}
              activeDay={activeDay}
              activeHoods={activeHoods}
              activeGender={activeGender}
              clearFilters={clearFilters}
              leagues={visibleLeagues}
              showGrid={showGrid}
            />

            <p className="pb-2 text-right"><small>Powered by <a target="_parent" href="https://www.volosports.com">Volo</a></small></p>
          </React.Fragment>
        )}
      </div>
      {showScrolling && <Scrolling />}
    </div>
  );
};

export default compose(
  withRouter,
  withState('loading', 'setLoading', true),
  withState('error', 'setError', null),
  withState('leagues', 'setLeagues', []),
  withState('showMaps', 'setMaps', false),
  withState('showGrid', 'setGrid', true),
  withState('totalCount', 'setTotalCount', 0),
  withState('availableSports', 'setAvailableSports', []),
  withState('availableHoods', 'setAvailableHoods', []),
  withState('selectedSports', 'setSelectedSports', []),
  withState('selectedHoods', 'setSelectedHood', []),
  withState('selectedGenders', 'setSelectedGenders', []),
  withState('selectedDays', 'setSelectedDays', []),
  withLeagueFilterHandlers(),
  lifecycle({
    async componentDidMount() {
      const {
        match,
        location,
        setLoading,
        setLeagues,
        setError,
        setGrid,
        setMaps,
        setSelectedSports,
        setActiveGender,
        setActiveDay,
        setTotalCount,
        setAvailableSports,
        setAvailableHoods,
        setSelectedHood,
        setSelectedGenders,
        setSelectedDays,
      } = this.props;

      const { organizationId } = _.get(match, 'params') || {};
      if (!organizationId) {
        setError('Invalid URL.');
        return;
      }

      // Because URLSearchParams didn't work
      const paramsList = location.search.slice(1).split("&");
      const params = {};
      for (const entry in paramsList) {
        const entryData = paramsList[entry].split("=");
        params[entryData[0]] = entryData[1];
      }

      let sportNames = params["sportName"] ? decodeURI(params["sportName"]).split(',') : [];
      let areas = params["area"] ? decodeURI(params["area"]).replace('%26', '&').split(',') : [];
      let gender = params["gender"] ? decodeURI(params["gender"]) : null;
      let day = params["day"] ? decodeURI(params["day"]).split(',') : [];

      day = _.map(day, d => _.capitalize(d));

      setActiveGender(gender);
      setActiveDay(day);

      if (sportNames && !Array.isArray(sportNames)) sportNames = [sportNames];
      if (areas && !Array.isArray(areas)) areas = [areas];
      if (gender && !Array.isArray(gender)) gender = [gender];
      if (day && !Array.isArray(day)) day = [day];

      setMaps(params["maps"] === "1");
      setGrid(params["list"] !== "1");

      // set up anything other params here

      setSelectedSports(sportNames);
      setSelectedHood(areas);
      setSelectedDays(day);
      setSelectedGenders(gender);

      setLoading(true);
      try {
        const res = await axios.get(`${baseUrl}/papi/v1/leagues/discoverable`, {
          params: {
            organizationId,
            sportName: sportNames,
            area: areas,
            gender,
            days: day,

          }
        });
        const data = res.data;
        if (data.message) {
          setError(data.message);
          return;
        }
        setLeagues(data.leagues);
        setTotalCount(data.totalCount);
        setAvailableSports(data.availableSports);
        setAvailableHoods(_.sortBy(data.availableNeighborhoods));
      } catch (e) {
        console.error(e);
        setError(_.get(e, 'response.data') || 'Unknown error getting leagues.');
      } finally {
        setLoading(false);
      }
    },
  }),
  withHandlers({
    updateView: props => async () => {
      const {
        match,
        setLoading,
        setLeagues,
        setError,
        setTotalCount,
        setAvailableSports,
        setAvailableHoods,
        selectedSports,
        selectedHoods,
        selectedGenders,
        selectedDays,
      } = props;

      const { organizationId } = _.get(match, 'params') || {};
      if (!organizationId) {
        setError('Invalid URL.');
        return;
      }
      setLoading(true);
      try {
        const res = await axios.get(`${baseUrl}/papi/v1/leagues/discoverable`, {
          params: {
            organizationId,
            sportName: selectedSports,
            area: selectedHoods,
            gender: selectedGenders,
            days: selectedDays,
          }
        });
        const data = res.data;
        if (data.message) {
          setError(data.message);
          return;
        }
        setLeagues(data.leagues);
        setTotalCount(data.totalCount);
        setAvailableSports(data.availableSports);
        setAvailableHoods(_.sortBy(data.availableNeighborhoods));
      } catch (e) {
        console.error(e);
        setError(_.get(e, 'response.data') || 'Unknown error getting leagues.');
      } finally {
        setLoading(false);
      }
    }
  }),

)(HostLeaguesPage);
