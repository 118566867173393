// Soccer until new generic is added
import defaultSportIcon from '../images/color/soccer@3x.png';
import defaultWhiteSportIcon from '../images/white/soccer@3x.png';

const sportIconSwitch = (sportName, size = '@3x') => {
  switch (sportName) {
    case 'Axe Throwing':
      return require(`../images/color/axethrowing${size}.png`);
    case 'Badminton':
      return require(`../images/color/badminton${size}.png`);
    case 'Bar Games':
      return require(`../images/color/bargames${size}.png`);
    case 'Baseball':
      return require(`../images/color/baseball${size}.png`);
    case 'Basketball':
      return require(`../images/color/basketball${size}.png`);
    case 'Board Games':
      return require(`../images/color/boardgames${size}.png`);
    case 'Bocce':
      return require(`../images/color/bocceball${size}.png`);
    case 'Bootcamp':
      return require(`../images/color/bootcamp${size}.png`);
    case 'Bowling':
      return require(`../images/color/bowling${size}.png`);
    case 'Classes':
    case 'Class':
      return require(`../images/color/fitnessclass${size}.png`);
    case 'Cornhole':
      return require(`../images/color/cornhole${size}.png`);
    case 'Darts':
      return require(`../images/color/darts${size}.png`);
    case 'Dodgeball':
      return require(`../images/color/dodgeball${size}.png`);
    case 'Event':
      return require(`../images/color/event${size}.png`);
    case 'Field Hockey':
      return require(`../images/color/fieldhockey${size}.png`);
    case 'Flip Cup':
      return require(`../images/color/flipcup${size}.png`);
    case 'Flag Football':
    case 'Football':
      return require(`../images/color/football${size}.png`);
    case 'Futsal':
      return require(`../images/color/futsal${size}.png`);
    case 'Golf':
      return require(`../images/color/golf${size}.png`);
    case 'KanJam':
      return require(`../images/color/kanjam${size}.png`);
    case 'Kickball':
      return require(`../images/color/kickball${size}.png`);
    case 'Laser Tag':
      return require(`../images/color/lasertag${size}.png`);
    case 'Lawn Bowling':
    case 'Shuffle Board':
      return require(`../images/color/lawnbowling${size}.png`);
    case 'Pickleball':
      return require(`../images/color/pickleball${size}.png`);
    case 'Ping Pong':
    case 'Table Tennis':
      return require(`../images/color/pingpong${size}.png`);
    case 'Running':
    case 'Marathon':
    case 'Race':
      return require(`../images/color/running${size}.png`);
    case 'Sailing':
      return require(`../images/color/sailing${size}.png`);
    case 'Skeeball':
      return require(`../images/color/skeeball${size}.png`);
    case 'Soccer':
      return require(`../images/color/soccer${size}.png`);
    case 'Softball':
      return require(`../images/color/softball${size}.png`);
    case 'Tennis':
      return require(`../images/color/tennis${size}.png`);
    case 'Trip':
      return require(`../images/color/trip${size}.png`);
    case 'Ultimate Frisbee':
    case 'Frisbee':
      return require(`../images/color/frisbee${size}.png`);
    case 'Volleyball':
      return require(`../images/color/volleyball${size}.png`);
    case 'Volunteer':
      return require(`../images/color/volunteer${size}.png`);
    case 'Yoga':
      return require(`../images/color/yoga${size}.png`);
    default:
      return defaultSportIcon;
  }
};

// todo: these are too small to use larger than 24x24
const sportIconWhiteSwitch = (sportName, size = '@3x') => {
  switch (sportName) {
    case 'Axe Throwing':
      return require(`../images/white/axethrowing${size}.png`);
    case 'Badminton':
      return require(`../images/white/badminton${size}.png`);
    case 'Bar Games':
      return require(`../images/white/bargames${size}.png`);
    case 'Baseball':
      return require(`../images/white/baseball${size}.png`);
    case 'Basketball':
      return require(`../images/white/basketball${size}.png`);
    case 'Board Games':
      return require(`../images/white/boardgames${size}.png`);
    case 'Bocce':
      return require(`../images/white/bocceball${size}.png`);
    case 'Bootcamp':
      return require(`../images/white/bootcamp${size}.png`);
    case 'Bowling':
      return require(`../images/white/bowling${size}.png`);
    case 'Classes':
    case 'Class':
      return require(`../images/white/fitnessclass_${size}.png`);
    case 'Cornhole':
      return require(`../images/white/cornhole${size}.png`);
    case 'Darts':
      return require(`../images/white/darts${size}.png`);
    case 'Dodgeball':
      return require(`../images/white/dodgeball${size}.png`);
    case 'Event':
      return require(`../images/white/event${size}.png`);
    case 'Field Hockey':
      return require(`../images/white/fieldhockey${size}.png`);
    case 'Flip Cup':
      return require(`../images/white/flipcup${size}.png`);
    case 'Flag Football':
    case 'Football':
      return require(`../images/white/football${size}.png`);
    case 'Futsal':
      return require(`../images/white/futsal${size}.png`);
    case 'Golf':
      return require(`../images/white/golf${size}.png`);
    case 'KanJam':
      return require(`../images/white/kanjam${size}.png`);
    case 'Kickball':
      return require(`../images/white/kickball${size}.png`);
    case 'Laser Tag':
      return require(`../images/white/lasertag${size}.png`);
    case 'Pickleball':
      return require(`../images/white/pickleball${size}.png`);
    case 'Ping Pong':
    case 'Table Tennis':
      return require(`../images/white/pingpong${size}.png`);
    case 'Running':
    case 'Marathon':
    case 'Race':
      return require(`../images/white/running${size}.png`);
    case 'Sailing':
      return require(`../images/white/sailing${size}.png`);
    case 'Skeeball':
      return require(`../images/white/skeeball${size}.png`);
    case 'Soccer':
      return require(`../images/white/soccer${size}.png`);
    case 'Softball':
      return require(`../images/white/softball${size}.png`);
    case 'Tennis':
      return require(`../images/white/tennis${size}.png`);
    case 'Trip':
      return require(`../images/white/trip${size}.png`);
    case 'Ultimate Frisbee':
    case 'Frisbee':
      return require(`../images/white/frisbee${size}.png`);
    case 'Volleyball':
      return require(`../images/white/volleyball${size}.png`);
    case 'Volunteer':
      return require(`../images/white/volunteer${size}.png`);
    case 'Yoga':
      return require(`../images/white/yoga${size}.png`);
    default:
      return defaultWhiteSportIcon;
  }
};

export const getSportIcon = (sportName, size) => {
  try {
    return sportIconSwitch(sportName, size);
  } catch (e) {
    console.error(e);
    return defaultSportIcon;
  }
};

export const getSportIconWhite = (sportName, size) => {
  try {
    return sportIconWhiteSwitch(sportName, size);
  } catch (e) {
    console.error(e);
    return defaultWhiteSportIcon;
  }
};

// deprecate
export const getSportIconColor = getSportIcon;
